<template>
    <div class="member-list">
        <van-sticky>
            <div class="member-list__search">
                <van-search
                    class="my-search"
                    v-model="value"
                    shape="round"
                    placeholder="输入会员名称"
                    @search="onSearch"
                >
                </van-search>
            </div>
        </van-sticky>
        <van-pull-refresh class="my-pull-refresh"  success-text="刷新成功" v-model="isLoading" @refresh="onRefresh">
            <van-list
                v-model="loading"
                :finished="finished"
                @load="onLoad1"
                class="my-list"
                >
                <div class="my-member-list">
                   <div class="relative" v-for="item in list" :key="item.id" @click="goMember(item.id)">
                        <div class="relative-avater">
                           <van-image class="member-image" round fit="cover" :src="getImg(item.picHeadUrl)" alt="">
                                <template v-slot:error><van-image round class="member-image" fit="cover" :src="avatar" alt=""/></template>
                            </van-image>
                            <div class="name">
                                {{item.memberName}}
                            </div>
                        </div>
                        <div class="info horse">
                            <div class="label ">手机号</div>
                            <div class="value">{{item.mobile || '***'}}</div>
                        </div>
                        <div class="info">
                            <div class="label">生日</div>
                            <div class="value">{{item.birthDate || '***'}}</div>
                        </div>
                        <div class="info">
                            <div class="label">卡券</div>
                            <div class="value">{{item.cardHours || 0}}</div>
                        </div>
                        <div class="info">
                            <div class="label">余额</div>
                            <div class="value">{{item.amount || 0}}</div>
                        </div>
                        <div class="info">
                            <div class="label">积分</div>
                            <div class="value">{{item.integral || 0}}</div>
                        </div>
                        <div class="circle left-top"></div>
                        <div class="circle right-top"></div>
                    </div>
                </div>
                <no-more v-show="showNoMore && !loading"/>
            </van-list>
            <no-data v-show="showData"/>
        </van-pull-refresh>
    </div>
</template>

<script>
import mixinPage from '@/utils/mixinPage';
export default {
    mixins: [ mixinPage ],
    data() {
        return {
            api: 'person/base/member/member-list',
            avatar: require('@/assets/img/avatar.jpeg'),
            model: {},
            value: '',
        }
    },
    methods: {
        init() {
            this.initList()
        },
        onSearch() {
            this.$set(this.model, 'keyword', this.value)
            this.init()
        },
        goMember(id) {
            this.$router.push({path:'/person/detailclass', query:{
                id
            }})
        }
    },
    mounted() {
        console.log(this.$route.query.personId)
        this.$set(this.model, 'personId', this.$route.query.personId)
        this.init()
    }
    
}
</script>

<style lang="scss">
    @import '~@/assets/css/var';
    .member-list{ 
        .my-pull-refresh{
            margin-top: 20px;
            padding: 0 20px;
            .relative{
                margin-bottom: 20px;
                box-shadow: 0 0 8px 6px $boxShadow;
                border-radius: 9px;
                padding: 10px;
                position: relative;
                overflow: hidden;
                background-color: #fff;
                .member-image{
                    width: 50px;
                    height: 50px;
                }
                &-avater{
                    display: flex;
                    align-items: center;
                    //justify-content: space-between;
                    padding: 0 10px 11px;
                    .name{
                        font-size: 16px;
                        color: $main;
                        font-weight: bold;
                        padding-right: 5px;
                        padding-left: 15px;
                    }
                }
                &-code{
                    color: $gray;
                }
                .horse{
                    padding-top: 10px;
                    border-top: 1px dashed $gray;
                }
                .last{
                    padding-bottom: 10px;
                }
                .info{
                    display: flex;
                    justify-content: space-between;
                    line-height: 30px;
                    padding-left: 10px;
                    padding-right: 10px;
                    .label{
                        color: $gray;
                    }
                } 
                .color{
                    .value{
                        color: $main;
                    }
                }   
                .bottom{
                    padding-top: 9px;
                    border-top: 1px dashed $gray;
                    .btn{
                        display: flex;
                        justify-content: flex-end;
                        .van-button{
                            background-color: $main;
                            border: 0;
                            padding: 0 30px;
                            color: #fff;
                            height: 30px;
                            margin-right: 5px;
                        }
                    }
                    .tip{
                        padding: 5px 10px 5px 10px;
                        text-align: center;
                        color: $pink;
                        min-width: 100px;
                        max-width: 180px;
                    }
                }
                .circle{
                    height: 20px;
                    width: 20px;
                    position: absolute;
                    border-radius: 50%;
                    background-color: $boxShadow;
                }
                .left-top{
                    top: 60px;
                    left: -10px;
                }
                .right-top{
                    top: 60px;
                    right: -10px;
                }
                .tip-status, .tip-status-success{
                    width: 50px;
                    border-radius: 50%;
                    border: 1px solid $red;
                    position: absolute;
                    top: -8px;
                    right: -8px;
                    line-height: 50px;
                    text-align: center;
                    color: $red;
                    transform: rotate(45deg);
                    
                }
                .tip-status-success{
                    border: 1px solid $green !important;
                    color: $green !important;
                }
            }
        }
        &__search {
            // margin: 40px 0 20px;
            padding: 0 20px 0 8px;
            background-color: #fff;
            .van-search__action{
                color: $main;
            }
        }
    }
</style>